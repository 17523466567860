<template>
    <v-container>
        <base-material-card icon="mdi-factory" title="Movimientos" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn @click="$router.push({name: 'Documento'})">Regresar</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-text-field :value="documento.vendedor.razon_social" label="Vendedor" disabled dense></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field :value="documento.fecha" label="Fecha" disabled dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field :value="formatoMoneda(documento.monto)" label="Monto" disabled dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field :value="formatoMoneda(documento.saldo)" label="Saldo" disabled dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field :value="documento.status" label="Status" disabled dense></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea :value="documento.observaciones" label="Observaciones" auto-grow disabled dense></v-textarea>
                </v-col>
            </v-row>
        </base-material-card>
        <v-card>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers" :items="movimientos" hide-default-footer disable-pagination show-expand>
                    <template v-slot:item.monto="{item}">
                        {{formatoMoneda(item.monto)}}
                    </template>
                    <template v-slot:item.venta="{item}">
                        {{item.venta_id ? item.venta_id.toString().padStart(5, '0') : 'N/A'}}
                    </template>
                    <template v-slot:item.saldo="{item}">
                        <span :class="{'red--text' : item.saldo}">
                            {{formatoMoneda(item.saldo)}}
                        </span>
                    </template>
                    <template v-slot:item.status="{item}">
                        {{item.status}}
                    </template>
                    <template v-slot:item.cuenta="{item}">
                        {{item.cuenta ? `${item.cuenta.banco} ${item.cuenta.numero}` : 'N/A'}}
                    </template>
                    <template v-slot:item.acciones="{item}">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn fab icon x-small v-on="on" @click="showImagenes(item)">
                                    <v-icon>far fa-file-image</v-icon>
                                </v-btn>
                            </template>
                            <span>Fotos</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-show="item.status == 'Pendiente'" fab icon x-small v-on="on" @click="modalMotivo(item)">
                                    <v-icon>far fa-thumbs-down</v-icon>
                                </v-btn>
                            </template>
                            <span>Rechazar</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-show="item.status == 'Pendiente' && item.monto <= documento.saldo" fab icon x-small v-on="on" @click="autorizar(item)">
                                    <v-icon>far fa-thumbs-up</v-icon>
                                </v-btn>
                            </template>
                            <span>Autorizar</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td v-if="item.venta" :colspan="headers.length / 2">
                            <v-list disabled>
                                <v-subheader>MONTOS</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item two-line v-if="item.venta">
                                        <v-list-item-content>
                                            <v-list-item-title>Monto de venta</v-list-item-title>
                                            <v-list-item-subtitle>{{formatoMoneda(item.venta.total)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line v-if="item.diferencia">
                                        <v-list-item-content>
                                            <v-list-item-title>Diferencia de precio</v-list-item-title>
                                            <v-list-item-subtitle>{{formatoMoneda(item.diferencia.precio)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line v-if="item.diferencia">
                                        <v-list-item-content>
                                            <v-list-item-title>Diferencia de impuestos</v-list-item-title>
                                            <v-list-item-subtitle>{{formatoMoneda(item.diferencia.impuesto)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line v-if="item.diferencia">
                                        <v-list-item-content>
                                            <v-list-item-title>Descuentos</v-list-item-title>
                                            <v-list-item-subtitle>{{formatoMoneda(item.diferencia.descuento)}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item two-line v-if="item.venta">
                                        <v-list-item-content>
                                            <v-list-item-title>Folio de Recibo</v-list-item-title>
                                            <v-list-item-subtitle>{{item.recibo_folio}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-subheader></v-subheader>
                            </v-list>
                        </td>
                        <td :colspan="headers.length / 2">
                            <v-list v-if="item.motivo_rechazo" disabled>
                                <v-subheader>AUTORIZACIÓN</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>Motivo de rechazo</v-list-item-title>
                                            <v-list-item-subtitle>{{item.motivo_rechazo}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                                <v-subheader></v-subheader>
                            </v-list>
                        </td>
                    </template>
                </v-data-table>
                <!-- <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.last_page" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col> -->
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">¿Desea autorizar el movimiento?</v-card-title>
                <v-card-text>{{formatoMoneda(movimiento.monto)}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        <v-icon left>fas fa-times</v-icon>
                        Cerrar
                    </v-btn>
                    <v-btn text color="error" @click="modalMotivo(movimiento)">
                        <v-icon left>fas fa-thumbs-down</v-icon>
                        Rechazar
                    </v-btn>
                    <v-btn text color="success" @click="autorizar(movimiento)">
                        <v-icon left>fas fa-thumbs-up</v-icon>
                        Autorizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogMotivo" persistent max-width="500">
            <v-card>
                <v-card-title class="headline">Indique el motivo</v-card-title>
                <v-card-text>
                    <v-textarea v-model="motivo" label="Motivo" :error-messages="errors.motivo_rechazo"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogMotivo = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="rechazar">Aceptar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    export default {
        components: {
        },
        data: () => ({
            errors: {},
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Venta', value: 'venta', sortable: false },
                { text: 'Fecha', value: 'fecha', sortable: false },
                { text: 'Tipo', value: 'tipo', sortable: false },
                { text: 'Monto', value: 'monto', sortable: false },
                { text: 'Cuenta', value: 'cuenta', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Autorizó', value: 'usuario.name', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ],
            movimientos: [],
            pagination: {
                perPage: 10,
                last_page: 1,
                page: 1
            },
            documento: {
                vendedor: {}
            },
            movimiento: {
                monto: 0
            },
            menu_fecha_inicio: false,
            menu_fecha_final: false,
            loading: false,
            dialog: false,
            dialogMotivo: false,
            motivo: null
        }),
        mounted() {
            this.load()
            this.loadDocumento()
        },
        methods: {
            load() {
                this.loading = true
                this.$http.get(`/documentos/${this.$route.params.id}/movimientos`, {
                    params: this.pagination
                }).then(response => {
                    this.movimientos = response.data.movimientos.map(movimiento => {
                        switch (movimiento.tipo) {
                            case "acvf":
                                movimiento.tipo = "ACVF"
                                break;
                            case "deposito":
                                movimiento.tipo = "Depósito"
                                break;
                            case "convenio":
                                movimiento.tipo = "Convenio"
                                break;
                        }

                        switch (movimiento.status) {
                            case "autorizado":
                                movimiento.status = "Autorizado"
                                break;
                            case "rechazado":
                                movimiento.status = "Rechazado"
                                break;
                            case "pendiente":
                                movimiento.status = "Pendiente"
                                break;
                        }

                        return movimiento
                    })

                    if (response.data.convenio) {
                        response.data.convenio.tipo = "Convenio"
                        this.movimientos.unshift(response.data.convenio)
                    }
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            loadDocumento() {
                this.$http.get(`/documentos/${this.$route.params.id}`).then(response => {
                    switch (response.data.status) {
                        case 'activo':
                            response.data.status = 'Activo'
                            break;

                        case 'liquidado':
                            response.data.status = 'Liquidado'
                            break;

                        default:
                            break;
                    }
                    this.documento = response.data
                })
            },
            showImagenes(movimiento) {
                this.movimiento = movimiento
                let imagenes = movimiento.imagenes.map(imagen => {
                    return {
                        type: 'image',
                        src: imagen.url,
                        opts: {
                            afterClose: (instance, current) => {
                                if (this.movimiento.status == 'Pendiente') {
                                    this.dialog = true
                                }
                            }
                        }
                    }
                })
                jQuery.fancybox.open(imagenes)
            },
            autorizar(movimiento) {
                this.loading = true
                this.$http.put(`/movimientos/${movimiento.id}/autorizar`).then(response => {
                    this.dialog = false
                    this.load()
                    this.loadDocumento()
                }).catch(error => {
                    switch (error.response.status) {
                        case 412:
                            this.$store.commit('SET_SNACKBAR', { text: error.response.data.message, color: 'red' })
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })

            },
            modalMotivo(movimiento) {
                this.movimiento = movimiento
                this.dialog = false
                this.dialogMotivo = true

            },
            rechazar() {
                this.loading = true
                this.errors = {}
                this.$http.put(`/movimientos/${this.movimiento.id}/rechazar`, {
                    motivo_rechazo: this.motivo
                }).then(response => {
                    this.dialogMotivo = false
                    this.load()
                }).catch(error => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })

            },
            setDates() {
                this.pagination.fecha_inicial = moment().format('YYYY-MM-DD')
                this.pagination.fecha_final = moment().format('YYYY-MM-DD')
            },
        }
    }
</script>